<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入标题" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.menu" clearable placeholder="请选择分类" size="small">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" clearable placeholder="请选择上架状态" size="small">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.tjstatus" clearable placeholder="请选择推荐状态" size="small">
          <el-option
              v-for="item in tjList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.supplier" clearable placeholder="请选择供应商态" size="small">
          <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch(0)">搜索</el-button>
        <el-button type="warning" size="small" @click="handleSearch(1)">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
      <span style="display: inline-block; margin-left: 10px;">
        <el-upload
            ref="upload"
            :action="uploadUrl"
            name="file"
            accept=".xls,.xlsx"
            :data="picdata"
            :show-file-list="false"
            :multiple="true"
            :headers="headers"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess"
            :on-error="uploadError">
          <el-button type="warning" icon="el-icon-upload2" size="small">导入</el-button>
        </el-upload>
        </span>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="title"
          label="标题">
      </el-table-column>
      <el-table-column
          prop="menu_ids_str"
          label="分类"
          align="center"
          width="200">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="car_mod_ids_str"-->
<!--          label="车型"-->
<!--          align="center"-->
<!--          width="200">-->
<!--      </el-table-column>-->
      <el-table-column
          prop="imgs"
          label="图片"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-avatar shape="square" :size="60" :src="scope.row.img"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
          prop="price"
          label="价格"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="partner_price"
          label="外部合作方价格"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          prop="old_price"
          label="建议零售价"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="cost_price"
          label="成本价"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="supplier_name"
          label="供应商"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="stock"
          label="库存"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="freight_free"
          label="是否包邮"
          align="center"
          width="100">
        <template slot-scope="scope">
          {{ scope.row.freight_free==1?'包邮':'不包邮'}}
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="上架状态"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-switch :value="scope.row.is_onsale==1" @change="changeSale(scope.row.id,scope.row.is_onsale==1?0:1)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="rec_time"
          label="推荐状态"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-switch :value="scope.row.rec_time>0" @change="changeRec(scope.row.id,scope.row.rec_time>0?0:1)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="ordering"
          label="排序"
          align="center"
          width="80">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

import config from "@/config/config";
import {getToken} from "@/utils/auth";

export default {
  components: {},
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      uploadUrl: config.HTTP_REQUEST_URL + 'admin/1.0/goods/import',
      headers: {
        authorization: 'Bearer ' + getToken()
      },
      picdata: {
        type: 'file'
      },
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: '',
        tjstatus: '',
        supplier: '',
        menu: '',
        isExport: 0
      },
      tableData: [],
      statusList: [
        {id:1,title:'上架'},
        {id:2,title:'下架'},
      ],
      tjList: [
        {id:1,title:'推荐'},
        {id:2,title:'不推荐'},
      ],
      supplierList: [],
      menuList: []
    };
  },
  created() {
    this.initData()
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable);
  },
  computed: {},
  methods: {
    initData() {
      this.getList();
      this.getSupplierList()
      this.getMenuList()
    },
    beforeUpload(file) {
      const excelMimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      if(!excelMimeTypes.includes(file.type)) {
        this.$message.error('请上传excel文件!');
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        this.$message.error('上传视频大小不能超过 25MB!');
      }
      return isLt2M;
    },
    uploadSuccess(res, file) {
      if(res.errcode == 0) {
        this.success('导入成功')
        this.getList()
      } else {
        this.fail(res.errmsg)
      }
    },
    uploadError(res, file) {
      this.fail('上传失败，请重新上传!')
    },
    handleVisiable(e) {
      if(e.target.visibilityState === 'visible') {
        this.initData()
      }
    },
    getSupplierList() {
      this.$api.merchant.supplierIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.supplierList = res.data.data
        }
      })
    },
    getMenuList() {
      this.$api.goods.menuAll({}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        tjstatus: that.search.tjstatus,
        supplier: that.search.supplier,
        menu: that.search.menu,
        is_export: that.search.isExport
      }
      this.$api.goods.goodsIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          if(that.search.isExport==1) {
            that.search.isExport = 0
            window.location.href = res.data.url
            return false
          }
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addGroup() {
      window.open('/goods/add')
      // this.$router.push({name: 'goods_add'})
    },
    editGroup(item) {
      window.open('/goods/add?id=' + item.id)
      // this.$router.push({name: 'goods_add', query: {id: item.id}})
    },
    changeSale(id,status) {
      this.$api.goods.goodsShenhe({id: id, is_onsale: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    changeRec(id,status) {
      this.$api.goods.goodsRec({id: id, is_rec: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.goods.goodsDel({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch(isExport) {
      this.search.isExport = isExport
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
